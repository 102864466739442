.category-carouselContainer {
  background: #acadae37;
  position: relative;
  width: 100%;
  height: 500px;
}

#category-carousel {
  perspective: 1000px;
  width: 45%;
  height: 500px;
  margin: auto;
}

#category-carousel-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}

#category-carousel-item {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s, opacity 1s;
  cursor: pointer;
  scale: 1.25;
  overflow: hidden;
}

#category-carousel-item img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
 scale: 1.25;
 
}

@media (max-width: 650px ){
  #category-carousel-item {
    scale: 1;
  }
}

@media (max-width: 500px ){
  #category-carousel-item {
    scale: 0.9;
  }
}

@media (max-width: 450px ){
  #category-carousel-item {
    scale: 0.8;
  }
}

@media (max-width: 350px ){
  #category-carousel-item {
    scale: 0.7;
  }
}


#category-carousel-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  opacity: 1;
 
}



.btn-Category {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

#category-carousel-navigation .left {
  position: absolute;
  left: 10%;
}

#category-carousel-navigation .right {
  position: absolute;
  right: 10%;
}
