.productSection-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

  }
  
  .productSection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 24px;
    margin-bottom: 60px; */
   
  }
  
  .productSection-title {
    font-weight: 300;
  }
  
  .productSection-button {
    background: transparent;
    border: none;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.2s linear;
    border-bottom: transparent solid 1px;
  }


 
  
  .productSection-button-icon {
    font-size: 14px;
    font-weight: 300;
    margin-left: 8px;
    margin-top: 0;
  }
  