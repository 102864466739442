.dropdown-menu li {
    position: relative;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }

    .dropdown-menu {
        position: relative;
      }
      
      .submenu {
        position: absolute;
        top: 0;
        left: calc(100% + 2px); 
        display: none;
        background-color: white;
        border: 1px solid #ccc;
        padding: 30px;
      }
      
      .dropdown-item {
        position: relative; 
        padding: 8px 15px;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        display: flex;
      }
      
      .dropdown-item:hover .submenu {
        display: block;
      }
      
      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        background-color: white;
        border: 1px solid #ccc;
        padding: 5px 0;
      }
      
      .dropdown-item {
        padding: 8px 15px;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        display: flex;
        color: black;
      }

      .selected-items{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: 2px solid black;
        padding: 8px;
        border-radius: 10px;
        min-width: 300px;
        max-width: 100%;
        min-height: 50px;
        max-height: 100%;
        overflow-x: auto;
        position: relative;
      }
      

      .selected-productdropdown {
        display: flex;
        overflow-x: hidden;
        overflow-y: hidden;
        max-width: 80%;
        white-space: nowrap;
        padding: 10px;
        gap: 10px; 
        align-items: center;
        border-radius: 35px;
        background-color:transparent; 
        position: relative;
        
    }
    
    .all-chips-container-ms{
      display: flex;
      padding-left: 10px;
    }

    .chip {
        display: inline-flex;
        align-items: center;
        padding: 2px 12px 2px 16px;
        background-color: rgb(168, 235, 193);
        font-weight: 400;
        color: rgb(0, 0, 0);
        font-size: 16px;
        border-radius: 20px;
        user-select: none;
        transition: all 300ms ease; 
        margin-right: 5px;
    }

    .chip.search-chip{
      background-color: rgb(168, 225, 235);
      padding-left: 8px;
    }

    .chip > .material-symbols-outlined.left-icon{
      margin-left: 0px;
      margin-right: 12px;
     
    }



    /* .chip:hover{
      border-radius: 16px;
    } */
    

    .chip:hover   {
border-radius: 17px;    }
  

    .chip .material-symbols-outlined {
        cursor: pointer;
        margin-left:3px;
        font-size: 19px;
       padding-top:3px;
      
    }
    
   
    .arrow {
        position: absolute;
        top: 70%;
        background-color: transparent;
        transform: translateY(-50%);
        cursor: pointer;
       
        color: black;
        border: none;
        
        padding: 5px 5px;
        z-index: 10;
    }

    
    .arrow:focus{
      color: black;
    }
    
    .arrow-left {
        left: 5px;
    }
    
    .arrow-right {
        right: 0px;
    }

   .btn-category2{
    background-color: none;
    border: 1 solid #ccc;
    border-radius: 10px;
    
   }
    
      .list-value{
        position: absolute;
      }
      .selected-item {
        border-radius: 30px;
        color: #ffffff;
        background-color: rgb(52, 52, 247);
        padding: 8px;
        margin: 5px;
      }
      
      .btn-category {
        padding: 8px;
        border-radius: 10px;
        border: none;
     
      }