.signup-form-container {
  width: 90%;
  max-width: 500px;
  height: auto;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .signup-form-container {
    width: 500px;
    height: 500px;
  }
}

.name-input {
  display: flex;
  justify-content: space-between;
}

.fname-input {
  border-radius: 20px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  padding: 12px 15px;
}
.lname-input {
  border-radius: 20px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  padding: 12px 15px;
}
.sign-in-label {
    text-align: right  ;
  margin: 0;
  font-size: 14px;
  color: #747474;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.sign-in-label:hover {
  color: #000;
  text-decoration: none;
}
