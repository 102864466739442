.sidebar-menu-category {
  min-width: 400px;
  height: 100%;
  background-color: #343a40;
  color: white;
  padding: 10px;
  overflow-y: auto;
  position: fixed;
  z-index: 10000;
  
}

.sidebar-header-category {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.sidebar-body-category {
  display: flex;
  flex-direction: column;
}



.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
 
 
}
.category-item-container{
  position: relative;
   
}



.category-item-container .line {
  position: absolute;
  top:23px;
  left: 22px;
  width: 2px;
  height: 0;
  background-color: white;
  transform: translateX(-50%);
  transition: height 0.3s ease;
}

.category-item-container .expanded .line {
  height: 92%; 
}

.category-item .material-symbols-outlined {
  cursor: pointer;
}



.category-item .span-li {
  flex: 1;
  margin-left: 10px;
  font-size: 19px;
}

.inputModal {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  background-color: transparent;
  z-index: 2;
  color:white;
  margin-bottom: 20px;
}

.inputModal-sub {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  background-color: transparent;
  z-index: 2;
  color:#000;
}

.inputModal-label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  color:white;
  pointer-events: none;
  transition: all 0.3s ease;
}

.inputModal-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #007bff;
  transition: all 0.3s ease;
}

.inputModal:focus + .inputModal-label {
  top: -20px;
  font-size: 14px;
  font-weight: 300;
  color: #075bb5;
}

.inputModal:focus + .inputModal + .inputModal-highlight {
  width: 100%;
}

.set-position {
  position: relative;
}

.btn-addsubcategory {
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  background-color: green;
  border-radius: 20px;
  padding: 0px 6px;
  height: 35px;
  width: 160px;
 font-size: 14px;
  cursor: pointer;
  border: none;
}



.sidebar-body-category ul {
  font-size: 12px; 
  list-style: none;
 
}

.sidebar-body-category ul li {
  font-size: 14px; 
  list-style: none;
}
.sidebar-body-category  li {
  font-size: 17px; 
  list-style: none;
}

.submenu-item {
  max-height: 0;  
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.submenu-item.expanded {
  margin:0px !important;
  max-height: 100%; 
  opacity: 1;
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}


.modal-category{
  z-index: 10000
}

.listItem-cateogry{
  cursor: pointer;
  color: black;
  border: 1px solid #E5E7EB;
  z-index: 1001;
}

.search__bar{
  position: relative;
 
}

.Search__Add {
  display: flex;
  position: relative;
  width: 100%;
}

.scrollable-category-list {
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
}