.navBottom {
  display: flex;
  align-items: center;
  padding: 22px 20px;
  width: 100%;
  background: #fff;
}

.navBottom-dropdown > .navBottom-btn {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: 20px;
  color: #000;
  position: relative;
  text-decoration: none;
  margin-right: 20px;
}

.navBottom-dropdown:hover > .navBottom-btn {
  color: #f10c2a;
}

.navBottom-dropdown > .navBottom-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #f10c2a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navBottom-dropdown:hover > .navBottom-btn::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navBottom-dropdown:hover .navBottom-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
  z-index: 100;
}

.navBottom-menu {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: absolute;
  top: 124px;
  left: 0;
  width: 100%;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: 50% 0%;
  box-shadow: 0 40px 60px rgb(0 0 0 / 3%);
  transition: 0.5s;
  padding: 20px;
}

.navBottom-menu-item-container {
  display: flex;
  grid-template-columns: repeat(4, 1fr); 
  grid-template-rows: repeat(2, auto); 
  gap: 10px; 
}

.navBottom-menu-item {

  cursor: pointer;
  font-size: 16px;
  border: none;
  background: none;
  padding: 5px 0;
}


.navBottom-menu-item-container-child{
  display: flex;

  
}
.navBottom-menu-item-child {
  color: red;
 
}

.navBottom-menu-item:hover {
  color: #000;
}

.navBottom-image {
  max-width: 100%;
  height: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.navBottom-image img {
  width: 175px;
  height: auto;
  border-radius: 7px;
}
