.container-DataTable {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  height: 94vh;
  padding: 20px;
  background-color: #ffffff;
  color: #000000;
  font-family: "Poppins", sans-serif;
  
 border:none;
  box-sizing: border-box; 
 margin-right: 20px;
 margin-left: 20px;
}

.Inventory__Button{
  border: none;
  border-radius: 32px;
  background-color: #2563eb;
  color: white;
  font-weight: 300;
  padding: 8px;
}

.header-DataTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: transparent;
}

.header-DataTable h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
}

.search-input {
  padding: 7px;
  border: 1px solid #4b5563;
  background-color: #ffffff;
  color: #000000;
  width: 400px;
  border-radius: 30px;
}

.filter-button {
  padding: 8px 16px;
  background-color: #2563eb;
  font-weight: 300;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: #1e40af;
}

.dropdown-Filter {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 16px;
  top: 120px;
  right: 10px;
  z-index: 1;
  width: 320px;
  color: #000000;
}

.filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
/* .table-container{
  border: 1px solid #ec1919;
  border-radius: 15px;
  
} */
.custom-table {
  width: 100%;
  max-width: 100%;
  color: #000000;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid #d4d4d4; /* Border on all sides */
  border-collapse: separate;
  border-spacing: 0;
 
}



.custom-table th,
.custom-table td {
  border-bottom: 1px solid #d4d4d4; 
  padding: 12px 15px;
  text-align: left;
  word-wrap: break-word;
  white-space: nowrap;
  word-break: break-word;
  
}




.custom-table tbody tr:last-child td {
  border-bottom: 1px solid transparent; 
}

.custom-table th {
  font-weight: 300;
  font-size: 21px;
}
.custom-table td {
  font-weight: 300;
  font-size: 16px;
 
}


.custom-table th::after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.custom-table th.asc::after {
  border-bottom: 4px solid #000000;
}

.custom-table th.desc::after {
  border-top: 4px solid #000000;
}



.custom-table tbody tr:hover {
  background-color: #f3f3f3;
}

.footer-DataTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  background-color: transparent;
}

.pagination button {
  padding: 5px 10px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #1e40af;
}

.pagination button:hover {
  background-color: #1e40af;
}

.per-page {
  display: flex;
  align-items: center;
  color: #000000;
}

.per-page label {
  margin-right: 10px;
  color: #000000;
}

.per-page select {
  padding: 5px;
  border: 1px solid #4b5563;
  background-color: #ffffff;
  color: #000000;
  border-radius: 4px;
}

.Button-Container {
  border-radius: 20px;
  padding: 8px 12px;
  border: none;
  gap: 8px;
  font-weight: 300;
}

.styled-button {

  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius:50%;
  background-color:transparent;
  color: #000000;
  transition: all 0.1s ease;
  
}
.styled-button:hover {
background-color: rgb(255, 255, 255);
box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;transition: all 0.1s ease;
}

.Actions {
  display: flex;
  gap: 8px;
  

}




.image-container {
  width: 90px;
  height: 90px;
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
}

.imgMain {
  max-width: 100%;
  max-height: 100%;
  padding: 3PX;
  mix-blend-mode: multiply;
  object-fit: contain; 
}

.modal-body{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F4F6;
}

.modal-container{
  padding: 0;
  margin: 0;
}

.card-header{
  background-color: #61729d !important;
}

.text-white{
  font-weight: 300;
  margin: 0;
}

.card-components{
  padding: 0 16px;
}

.title-text{
  font-weight: 300;
}

#productPrice{
  margin: 0 0 8px 0;
}

.Styled-Button{
  font-weight: 300;

  font-size: 17px;
  padding: 12px;

}

.Back-Button{
  background-color: #61729d;
  transition: 0.15s ease-in background-color;
}

.Back-Button:hover{
  background-color: #3a4f8a;
}

.Create-Button{
  background-color: #559043;
  opacity: 0.8;
  transition: 0.15s ease-in background-color;
}

.Create-Button:hover{
  background-color: #326422;
}

.Modal-Footer{
  border: none;
  display: flex;
  justify-content: end;
  gap: 10px;
}

.no-margin{
  margin-bottom: 0 !important;
}

.selected-items{
  border-width: 0.05px !important;
  border-color: #b5b5b5 !important;
}

.btn-category{
  background-color: #4c4c4c0f;
}




@media (max-width: 768px) {
  .container-DataTable {
    padding: 8px;
    border-radius: 6px;
  }

  .header-DataTable h1 {
    font-size: 16px;
  }

  

  .custom-table {
    font-size: 12px;
  }

  .custom-table th,
  .custom-table td {
    padding: 8px 10px;
  }

  .image-container {
    width: 28px;
    height: 28px;
    border-radius: 6px;
  }

  .Actions {
    gap: 3px;
  }

  .styled-button {
    padding: 4px;
    font-size: 12px;
  }

  .imgMain {
    padding: 1px;
  }

  .filter-button {
    padding: 4px 8px;
    background-color: #2563eb;
    font-weight: 300;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .Button-Container {
    border-radius: 10px;
    padding: 4px 6px;
    border: none;
    gap: 4px;
    font-weight: 300;
    font-size: 12px;
  }

  .search-input {
    margin-top: 10px;
  }
  
}

@media (max-width: 450px) {
  .container-DataTable {
    padding: 4.8px;
    border-radius: 3.6px;
  }

  .header-DataTable h1 {
    font-size: 9.6px;
  }

  .custom-table {
    font-size: 7.2px;
  }

  .custom-table th,
  .custom-table td {
    padding: 4.8px 6px;
  }

  .image-container {
    width: 16.8px;
    height: 16.8px;
    border-radius: 3.6px;
  }

  .Actions {
    gap: 1.8px;
  }

  .styled-button {
    padding: 2.4px;
    font-size: 7.2px;
  }

  .imgMain {
    padding: 0.6px;
  }

  .filter-button {
    padding: 2.4px 4.8px;
    background-color: #2563eb;
    font-weight: 300;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 10px;
  }

  .Button-Container {
    border-radius: 6px;
    padding: 2.4px 3.6px;
    border: none;
    gap: 2.4px;
    font-weight: 300;
    font-size: 7.2px;
  }

  .pagination-custom{
    margin-top: 15px;
  }
  .pagination-custom .page-item .page-link {
    font-size: 12px;
   
}



}



