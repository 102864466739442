@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pagination-custom {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

footer {
  margin-top: 104px;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}
