.contact-map iframe {
  height: 400px;
  width: 100%;
  border: none;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.contact-information h3 {
  margin-bottom: 30px;
}
.contact-information ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.contact-information ul li:last-child {
  margin-bottom: 0;
}
.contact-information ul li .icon {
  width: 50px;
}
.contact-information ul li .icon i {
  font-size: 30px;
}
.contact-information ul li .text {
  max-width: calc(100% - 50px);
}
.contact-information ul li .text span,
.contact-information ul li .text a {
  display: block;
  line-height: 24px;
  max-width: 230px;
  text-decoration: none;
  color: #343a40;
  transition: color 300ms ease;
}
.contact-information ul li .text span:hover,
.contact-information ul li .text a:hover {
  color: #297f29;
}

.contact-form h3 {
  margin-bottom: 30px;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  border: 1px solid #eeeeee;
  padding: 10px 20px;
}
.contact-form input {
  height: 50px;
  margin-bottom: 1rem;
}
.contact-form textarea {
  height: 120px;
  resize: none;
}
.contact-form input[type="submit"],
.contact-form button,
.contact-form .submit {
  width: auto;
  height: 50px;
  border: none;
  padding: 5px 30px;
  background-color: #297f29;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 15px;
  border-radius: 5px;
  transition: all 300ms ease;
}
.contact-form input[type="submit"]:hover,
.contact-form button:hover,
.contact-form .submit:hover {
  background-color: #343a40;
}

@media (max-width: 992px) {
  .contact-information {
    margin-bottom: 50px;
  }

  .contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .col-12{
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .contact-information {
    margin-bottom: 30px;
  }
}

.form-message {
  line-height: 2;
}

@media (max-width: 988px) {
  .contacts-container {
    margin-top: 20px;
  }
}
