
#swiper-cardcasoul .swiper {
    width: 100%;
    height: 100%;
 
  }
  
  #swiper-cardcasoul .swiper-slide {
   
   
   
   
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  #swiper-cardcasoul .swiper-pagination-bullets{
    position: absolute;
    bottom:  20px;
   
   
  }
  #swiper-cardcasoul .swiper-pagination-bullets .swiper-pagination-bullet {
    background: #151414;
    transition: 0.3s;
    scale: 1.25;
    display: inline-block; 
    margin: -20px 5px;
     
  }

  .carousel-of-products{
    border-radius: 5px;
   
  }

  
.swiper-container {
  position: relative; 
}


.productCarsoul-next-button,
.productCarsoul-prev-button {
  color: #c0c0c0; 
  position: absolute;
  top: 50%;
  transform: translate(0,-50%); 
 
  z-index: 2; 
  border: none;
  background: #ffffff00;
  box-sizing: border-box;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
  font-size: 35px;
}

.productCarsoul-next-button {
  right: 0px; 
}

.productCarsoul-prev-button {
  left: 0px; 
}

.productCarsoul-next-button:hover,
.productCarsoul-prev-button:hover {
 
 background-color: #00000041;
  color: #ffffff;  


}

@media (max-width: 500px) {
    .productCarsoul-next-button,
    .productCarsoul-prev-button {
        top: 30px;
        width: 20px;
        height: 20px;
    }
}