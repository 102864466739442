.productlist-container {
  padding: 20px;
}
.productlist-pagination {
  display: flex;

  align-items: center;
}

.products-notfound {
  min-height: 500px;

  z-index: -1;
}

.productlist-title {
  font-weight: 300;
  font-family: "Times New Roman", Times, serif;
  margin-top: 10px;

  margin-bottom: 2rem;
}
.category-item-filter {
  /* background-color: #f0f4ff; */
  /* border-radius: 5px; */
  padding: 5px 10px;
  /* margin: 5px 0; */
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  font-size: 16px;
  /* margin-top:4px; */
  border-top: 1px solid rgb(221, 221, 221);

  position: relative;
}

/* .category-item-filter::after{
position: absolute;
bottom: 0;
height: 1px;
background-color: rgb(216, 216, 216);
width: 80%;
margin-left: 50%;
transform: translate(-50%,0);
content: "";
} */

.subcategory-item {
  font-size: 16px;
  /* padding: 8px 16px; */
  margin-left: 20px;
}

.flex-grow-ms {
  flex-grow: 1;
  min-height: 840px;
}

.no-products-found-ms {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.no-products-found-ms > h5 {
  font-weight: 300;
}

/* New styles for sub-subcategories */
.sub-subcategory-item {
  font-size: 16px;
  /* padding: 6px 14px; */
  margin-left: 40px;
}

.category-item-filter:hover {
  background-color: #fcfcfc;
  /* font-weight: 600; */
}

.category-item-filter.selected {
  /* background-color: #E5EBEF; */
  font-weight: 600;
}

.checkbox-category {
  display: none;
}

.chip-category {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  font-size: 14px;
  margin-right: 5px;

  border: 1px solid #ccc;
  border-radius: 20px;
  color: white;
  box-sizing: border-box;
  background-color: blueviolet;
}
.chips-container {
  height: 10px;
}

.btn-close-chip {
  cursor: pointer;
}

.selected-categories {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 80%;
  white-space: nowrap;
  padding: 10px;
  gap: 10px;
  align-items: center;
  border-radius: 35px;
  background-color: transparent;
  position: relative;
}

.product-list-container-ms {
  display: flex;
  flex-wrap: wrap;
  min-height: 500px;
}

.chips-container-ms {
  height: 40px;
}

.product-list-item-ms {
  width: 33.3%;
  height: 100%;
  margin-top: 10px;
}

.product-list-full-container-ms {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 900px;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.product-list-filter-container-ms {
  min-width: 200px;
  box-sizing: border-box;
  padding: 10px;
}

@media (max-width: 1200px) {
  .product-list-filter-container-ms {
    min-width: 175px;
  }

  .product-list-item-ms {
    width: 33.3%;
  }
}

@media (max-width: 900px) {
  .product-list-filter-container-ms {
    min-width: 175px;
  }

  .product-list-item-ms {
    width: 50%;
  }

  .product-list-full-container-ms {
    padding: 0 10px;
  }
}

@media (max-width: 624px) {
  .product-list-item-ms {
    width: 100%;
  }

  .product-list-full-container-ms {
    flex-direction: column-reverse;
    padding: 0 5%;
  }
}

.paginator-ms {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}

dl {
  margin-bottom: 0px !important;
}
