.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
}

/* .product-view-jf{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 2rem;

} */


.bullet-separated::after {
  content: "•";
  padding: 0px 8px;
  font-size: 18px;
  color: gray;
}

.bullet-separated:last-of-type::after {
  content: ""; 
}

.productdetial-btn {
  background: #000;
  color: white;
  border-radius: 40px;
  width: 100%;
  padding: 8px;
}
.btn-icons {
  margin-top: 10px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: black;
}

.btn-icons:focus {
  color: black;
}
.btn-accordian {
  background: transparent;
  border: none;
  border-radius: 200px;
  padding: 3px;
  font-size: 14px;

  color: white;
}
.btn-accordian:focus {
  color: white;
}
.accordion-content {
  color: #667085;
  font-size: 14px;
}

.btn-productcontainer {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  width: 400px;
  height: 40px;
  background-color: #ede9e9;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;

  display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 16px;

  font-weight: 300;
  top: 7px;
  right: 20px;
  color: #1d2939;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 200px;
  height: 36px;
  background: #fff;
  border-radius: 26px;
  position: absolute;
  font-size: 16px;
  display: flex;
  justify-content: center;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
  color: #222;
  font-weight: 300;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 33px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 198px;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 70px;
}

.title {
  text-align: left;
  text-transform: uppercase;
  margin: 0;
}
.colorCat {
  text-transform: uppercase;
  font-style: italic;
  color: gray;
  font-weight: 300;
  font-size: 14px;
}

.size span {
  display: inline-block;
  margin: 0 5px; /* Adds space between each size option */
  padding: 5px 10px; /* Adds padding inside each size option for better readability */
  border: 1px solid #000; /* Adds a border around each size option */
  border-radius: 5px; /* Rounds the corners of the border */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.size span:hover {
  color: #0056b3; /* Changes text color on hover */
}

.enquiry {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  font-size: 16px; /* Medium text size */
  padding: 10px 20px; /* Top and bottom padding of 10px, left and right padding of 20px */
  border: none; /* No border */
  border-radius: 5px; /* Slightly rounded corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.enquiry:hover {
  background-color: #0056b3; /* Darker blue background on hover */
}

.button-18 {
  align-items: center;
  background-color: #0a66c2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus {
  background-color: #16437e;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.button-18:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.button-18 span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 8px;
}

.breadcrumb-style {
  color: black; /* Light blue color for the text */
  font-size: 1.5rem; /* Adjust the font size as needed */
  font-weight: 300; /* Adjust the font weight as needed */
  padding: 8px 0; /* Add some padding for better spacing */
}

.breadcrumb-style :hover {
  color: #0a66c2;
}

.breadcrumb-style a {
  text-decoration: underline 1px;
  color: #494747;
}

.breadcrumb-style a:hover {
  color: #0a66c2;
}

.last-breadcrumb-item {
  color: black; /* Light blue color for the text */
  font-size: 1.5rem; /* Adjust the font size as needed */
  font-weight: 400;
  padding: 8px 0; /* Add some padding for better spacing */
}
