.about__us--row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section {
  position: relative;
  margin-top: 80px;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 40px;
  min-width: 50%;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-right: 100px;
}

.about-section .content-column .text {
  position: relative;
  color: #777777;
  font-size: 15px;
  line-height: 2em;
  margin-bottom: 40px;
}

.about-section .image-column {
  position: relative;
}

.about-section .image-column .inner-column {
  position: relative;
  padding: 40px 40px 0px 0px;
  margin-left: 50px;
}

.about-section .image-column .inner-column:after {
  position: absolute;
  content: "";
  right: 0px;
  top: 0px;
  left: 40px;
  bottom: 100px;
  z-index: -1;
  border: 2px dotted #297f29;
}

.about-section .image-column .inner-column .image {
  position: relative;
}

/* .about-section .image-column .inner-column .image:before{
	position:absolute;
	content:'';
	left:-50px;
	bottom:-50px;
	width:299px;
	height:299px;
	background:url(https://images.unsplash.com/photo-1489514354504-1653aa90e34e?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D) no-repeat;
} */

.about-section .image-column .inner-column .image img {
  position: relative;
  width: 100%;
  display: block;
  filter: brightness(80%);
}

.about-section .image-column .inner-column .image .overlay-box {
  position: absolute;
  left: 40px;
  bottom: 48px;
}

.about-section .image-column .inner-column .image .overlay-box .year-box {
  position: relative;
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4em;
  padding-left: 125px;
}

.about-section
  .image-column
  .inner-column
  .image
  .overlay-box
  .year-box
  .number {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 110px;
  height: 110px;
  color: #297f29;
  font-size: 68px;
  font-weight: 300;
  line-height: 105px;
  text-align: center;
  background-color: #eaeaea;
  border-radius: 20px;
}

.about-section .btn-style-three:before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  z-index: -1;
  right: -10px;
  bottom: -10px;
  background: url(https://i.ibb.co/DKn55Qz/pattern-1.jpg) repeat;
}

.about-section .btn-style-three:hover {
  color: #ffffff;
  background: #297f29;
}

.about-section .btn-style-three {
  position: relative;
  line-height: 24px;
  color: #252525;
  font-size: 18px;
  font-weight: 300;
  background: none;
  display: inline-block;
  padding: 11px 40px;
  background-color: #ffffff;
  text-transform: capitalize;
  border: 2px solid #297f29;
  font-family: "Arimo", sans-serif;
  transition: all 300ms ease;
  text-decoration: none;
}

.sec-title2 {
  color: #fff;
}

.sec-title {
  position: relative;
  padding-bottom: 40px;
}

.sec-title .title {
  position: relative;
  color: #297f29;
  font-size: 18px;
  font-weight: 300;
  padding-right: 50px;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: capitalize;
}

.sec-title .title:before {
  position: absolute;
  content: "";
  right: 0px;
  bottom: 7px;
  width: 40px;
  height: 1px;
  background-color: #bbbbbb;
}

@media (max-width: 995px) {
  .about__us--row {
    flex-direction: column;
    margin: 0 24px;
  }

  .about-section .content-column .inner-column {
    padding-right: 0;
  }

  .about-section .image-column .inner-column {
    margin-left: 0;
  }

  .about-section .content-column {
    min-width: 100%;
  }

  .image-column {
    margin-top: 72px;
    min-width: 75%;
  }
}
