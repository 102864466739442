.navbar-jf {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #e9ecef;
  padding: 0.5rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  max-height: 70px;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}

.navbar-invisible {
  height: 70px;
}

.navbar-invisible.open-more {
  height: 130px;
}

.navbar-jf.hide {
  box-sizing: border-box;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.left-content-jf,
.center-content-jf,
.right-content-jf {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-content-jf {
  justify-content: flex-start;
  flex: 1;
}

.center-content-jf {
  /* flex: 2; */
  display: flex;
  justify-content: center;
  height: 70x;
  overflow: hidden;
}

.right-content-jf {
  justify-content: flex-end;
  flex: 1;
  gap: 1rem;
}

.navbar-menu-button-jf {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 1.5vw;
}

.burger-menu-jf,
.search-icon-jf {
  width: 2rem;
  z-index: 1000;
}

.nav-logo-jf {

  width: 100%;
  max-width: 160px;
  min-width: 100px;
  cursor: pointer;
}

.call-us-link-jf {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  text-decoration: none;
}

.call-us-link-jf:hover {
  color: #000;
  text-decoration: none;
}

.search-bar-container {
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: white;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.search-bar-container.show {
  opacity: 1;
}

.search-input-jf {
  width: 40%;
  position: relative;
  padding: 8px;
  margin: 0 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.search-input-jf:focus {
  outline: none;
  border: 1px solid #5a6066;
}

.search-bar {
  transition: opacity 0.25s ease;
  opacity: 0;
  visibility: hidden;
}

.search-bar.visible {
  opacity: 1;
  visibility: visible;
}

.search-bar.hidden {
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .navbar-jf {
    padding: 0.5rem 1rem;
  }
  /* .nav-logo-jf {
    width: 15vw;
  } */
  .navbar-menu-button-jf {
    font-size: 2.5vw;
  }
  .search-input-jf {
    width: 60%;
  }
  .is-mobile {
    display: none;
  }
  .call-us-link-jf {
    display: flex;
    justify-content: flex-end;
    width: auto;
   
  }
  .search-input-jf {
    width: 100%;
  }
}
