.main-sidebar {
  width: 60px;
  margin-right: 10px;
  transition: width 0.2s ease;
  overflow: visible;
  z-index: 1000;
}
.main-sidebar.open {
  width: 260px;
  transition: width 0.8s ease;
}
.Admin-sidebar {
  position: fixed;
  overflow: hidden;
  margin-right: 10px;
  width: 60px;
  height: 100%;
  background: #383f51;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: width 0.2s ease;
}

.Admin-sidebar.open {
  width: 200px;
  height: 100%;
  transition: width 0.2s ease;
}

.sidebar-item .nested {
  padding-left: 20px;
}

.sidebar-inner {
  width: 200px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  height: 72px;
  background: rgb(0 0 0 / 15%);
}

.sidebar-burger {
  width: 60px;
  height: 72px;
  display: grid;
  place-items: center;
  color: #f9f9f9;
  background: transparent;
  border: none;
}

.logo-sidebar {
  height: 40px;
  margin-right: 10px;
  color: red;
}

.sidebar-menu {
  display: grid;
  padding: 10px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
 

  /* Custom scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.7);
    border-radius: 4px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.35), transparent);
    pointer-events: none;
  }
}
.sidebar-menu.scrolled::after {
  display: none;
}

.sidebar-button {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  width: 100%;
  font-family: "Poppins";
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 10px;
  border-radius: 8px;
  color: #f9f9f9;
  opacity: 0.8;
  background: transparent;
  border: none;
  cursor: pointer;
}
.logoutopen {
  
  position: fixed;
  bottom: 0;
  
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  font-family: "Poppins";
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 10px;
  border-radius: 8px;
  color: #f9f9f9;
  opacity: 0.8;
  background: transparent;
  border: none;
  cursor: pointer;
}
.homeopen {
  position: fixed;
  bottom: 56px;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  font-family: "Poppins";
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 10px;
  border-radius: 8px;
  color: #f9f9f9;
  opacity: 0.8;
  background: transparent;
  border: none;
  cursor: pointer;
}
.homeopen:hover {
  background: rgb(0 0 0 / 30%);
  width: 180px;
  opacity: 1;
}
.homeclose{
  position: fixed;
  bottom: 56px;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  font-family: "Poppins";
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 10px;
  border-radius: 8px;
  color: #f9f9f9;
  opacity: 0.8;
  background: transparent;
  border: none;
  cursor: pointer;


}
.homeclose:hover {
  background: rgb(0 0 0 / 30%);
  width: 50px;
  opacity: 1;
}
.logoutopen:hover {
  background: rgb(0 0 0 / 30%);
  width: 180px;
  opacity: 1;
}
.logoutclose {
  position: fixed;
  bottom: 0;
 width: 40px;
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  font-family: "Poppins";
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 10px;
  border-radius: 8px;
  color: #f9f9f9;
  opacity: 0.8;
  background: transparent;
  border: none;
  cursor: pointer;
}
.logoutclose:hover {
  background: rgb(0 0 0 / 30%);
  width: 50px;
  opacity: 1;
}

.sidebar-button:hover {
  background: rgb(0 0 0 / 30%);
  opacity: 1;
}

.sidebar-button:hover > span {
  opacity: 1;
}

.sidebar-logo,
.sidebar-button p {
  opacity: 0;
  transition: 0.3s;
}

.sidebar.open :is(.sidebar-button p, .sidebar-logo) {
  opacity: 1;
}

.sidebar-button > img {
  width: 24px;
  height: 24px;
}

.sidebar-button > span {
  opacity: 0.5;
}
