

.invetorydataTable {
    display: grid;
   
    width: 100%;
    max-height: 58vh;
    overflow-y: auto;
    overflow-x: hidden;
   
    position: relative;
   margin-left: 10px;
   
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
  
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155, 0.7);
      border-radius: 4px;
    }
  
   
  }
  .invetorydataTable::after {
    display: none;
  }

  .search-inventory-List {
    padding: 7px;
    border: 1px solid #4b5563;
    background-color: #ffffff;
    color: #000000;
    width: 90%;
    border-radius: 30px;
    margin-left: 10px;
    
   
   
  }
  .search-product-List {
    
    margin-left: 10px;
    z-index: 100;
  
    padding: 6px;
    border: 1px solid #4b5563;
    background-color: #ffffff;
    color: #000000;
    width: 70%;
   
    border-radius: 30px;
   
   
  }


  .inventory-List {
   
    max-width: 100%;
    max-height: 100%;
    border-collapse: separate;
    border-spacing: 6px;
    
    color: #000000;
  
    
  }

  
  
  .inventory-List td {
    border: 1px solid #656565;
   
    border-radius: 15px;
    cursor: pointer;
    
    color: #000000;
    width: 100%; 
   
    height: 70px; 
    padding: 10px; 
  }
  
 .inventory-cell{
  background-color: #ffffff;
 }
  
 
  
  .inventory-List td:hover {
    background-color: #d0d3d6;
  }

  .product-selected {
    background-color: #90ee90; 
  }


  .chosen-products{
    height:60vh;
    border: 1px solid #bebdbd;
    overflow:hidden;
    border-radius: 10px;
    
  }

  @media (max-width: 500px) {

    .search-product-List{
      margin-top: 9px;
      margin-left: 0px;
      
     
    }
  }
  
 