
.mySwiperProduct {
    width: 100%;
    height: 100%;
  }
  
  .SwiperSlideProduct {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
   
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .SwiperSlideProduct img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
  