/* ProductsListSkeleton.css */
.product-skeleton {
  width: 33.3%;
  padding: 5px;
  padding-top: 11px;
}



.products-list-skeleton {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 100%;

}

.skeleton-image{
    width: 100%;
    aspect-ratio: 1/1;
}

@media (max-width: 1200px) {

  .product-skeleton {
    width: 33.3%;
  }
}

@media (max-width: 900px) {
  .product-list-filter-container-ms {
    min-width: 175px;
  }

  .product-skeleton {
    width: 50%;
  }


}

@media (max-width: 624px) {
  .product-skeleton {
    width: 100%;
  }


}