.about-home {
  background-color: #eba243;
  padding: 50px 0;
  margin-top: 40px;
  border-radius: 4px;
  width: 100%;
}

.about-home .container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

.about-home-img img {
  max-width: 100%;
  min-height: 500px;
  object-fit: cover;
  height: auto;
  border-radius: 4px;
}

.about-home-text {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.about-home-text h2 {
  color: #fff;
  margin-bottom: 20px;
}

.about-home-text p {
  color: #fff;
  line-height: 1.6;
}

.p-text-ms{
  text-align: justify;
  font-size: 21px;
  font-weight: 400;
}

.btn-primary {
  background-color: rgb(95, 192, 100);
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  border-color: transparent;
}

.btn-primary-container{
  width:100%;
  display: flex;
  justify-content: end;
}

.btn-primary:hover {
  background-color: rgb(124, 211, 128);
}
.space-between-jf {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
