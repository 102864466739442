.product-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid rgb(227, 227, 227);
    border-radius: 4px;

}



.product-info {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.0);
    font-size: 14px;
    line-height: 20px;
}

.product-image {
    width: 70%;
    object-fit: cover;
    transition: all 300ms ease;



}


.product-card-ms {
    width: 100%;
    height: 100%;
    padding: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
    box-sizing: border-box;

}

.product-image-ms {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    transition: all 0.3s ease;
}

.product-card-ms:hover .product-image-ms {
    transform: scale(1.03);
    filter: brightness(0.95);
    border: none;
}

.product-card-ms:active {
    transform: scale(0.98);
}

.product-image-container-ms {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 15px;
    transition: border-color 300ms ease;
}

.product-card-ms:hover .product-image-container-ms{
    border-color: #949494;
}

.product-title-ms {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 0px 0px 0px 0px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;

}

.product-imageContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border-bottom: 1px solid rgb(227, 227, 227);
    overflow: hidden;
    transition: all 300ms ease;

}

.product-card:hover .product-image {
    transform: scale(1.03);
}

.product-card:hover .product-imageContainer {
    filter: opacity(80%)
}

.product-title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin: 16px 0;
    transition: all 300ms ease;
}

.product-card:hover .product-title {
    transform: scale(1.02)
}

.product-price {
    display: flex;
    justify-content: space-between;
    align-items: center;

}