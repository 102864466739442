.parallax-section {
  margin-top: 80px;
  margin-bottom: 64px;

}

.parallex-about-p {
  max-width: 900px;
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.8;
  text-align: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .parallex-about-p {
    padding: 10px;
    width: 90%;
  }
}