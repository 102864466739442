.sidebar__title{
  margin: 0 24px 16px;
  font-weight: 700;
}

.main__category{
  font-weight: 600;
  transition: color 300ms ease;
}

.item-sidebar--main{
  margin: 0 24px;
}

.main__category:hover{
  color: #297F29;
}

.nav-subcategory-name{
  font-weight: 700;
  transition: color 300ms ease;
}

.nav-subcategory-name:hover{
  color: #297F29;
}

.sidebar-jf {
  height: 100%;
  width: 400px;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: -400px;
  background-color: #f3ecec;
  overflow-x: hidden;
  overflow-y: auto; /* Add this line for vertical scrolling */
  transition: left 0.4s ease;
 
  color: rgb(27, 3, 3);
 
  scrollbar-color: #939292 #f3ecec; /* For Firefox */
}

.sidebar-jf.open {
  left: 0px;
}

.nav-subcategoery {
  font-size: 22px;
  font-weight: 300;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 30px;
  width: 100%;
}
.Side__Link--container{
  display: flex;
  gap: 16px;
  align-items: center;
}

.nav-icons{
  margin: 0 48px;
  display: flex;
  gap: 16px;
}

@media (max-width: 768px) {
  .sidebar-jf.open {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    min-width: 150px;
  }

}

.sidebar-jf .close-button {
  display: block;
  position: sticky;
  top: 20px;
  
  margin-left: 90%;
  border: none;
  background: none;
  color: rgb(26, 2, 2);
  font-size: 24px;
  cursor: pointer;
}

.sidebar-menu-items-jf {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0 5rem;
  width: 100%;
}

.item-sidebar {
  margin-left: 3rem;
  margin-right: 3rem;
}

.nav-icons{
  margin-top: 15px;
}

.arrow-back {
  margin-top: 5px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .sidebar-menu-items-jf {
    margin: 4rem 1rem 0 1rem;
  }
}

.sidebar-menu-items-jf li {
  padding: 10px 0;
  font-size: calc(1rem + 0.25vw);
  position: relative;
  cursor: pointer; /* Add this line */
}

@media (max-width: 768px) {
  .sidebar-menu-items-jf li {
    display: flex;
    justify-content: center;
  }
}

.sidebar-menu-items-jf li a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: opacity 0.5s ease;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: fit-content;
}

.sidebar-menu-items-jf li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: rgb(26, 2, 2);
  transition: width 0.5s ease;
}

.sidebar-menu-items-jf li a:hover::after {
  width: 100%;
}

.sidebar-closed .sidebar-menu-items-jf li a {
  opacity: 0;
  pointer-events: none;
}

.sidebar-menu-items-jf li a {
  overflow: hidden;
  white-space: nowrap;
}

.arrow-icon {
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: right 300ms ease;
}

.item-sidebar:hover .arrow-icon{
  right: 0;
}

.instagram-icon {
  font-size: 24px; 
  color: white;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  
 border-radius: 20%;
 
}
.facebook-icon{
  color: blue;
  font-size: 24px;
  margin-right: 10px;
}
