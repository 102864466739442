.swiperProduct {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px outset rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 0;
}

.swiperProduct img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.swiperProduct {
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.mySwiper2 {
  height: 47%;
  width: 100%;
  position: relative;
}

.mySwiper {
  padding: 10px 0;
  position: relative;
  margin: 20px 20px 0;
  width: 100%;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.mySwiper .swiperProduct {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border-radius: 10px;
  padding: 4px;
  filter: brightness(0.9);
}

.swiperproduct-container {
  width: 100%;
}

.swiperProduct img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.custom-next-button,
.custom-prev-button {
  color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: none;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.custom-prev-button {
  left: 8px;
}

.custom-next-button {
  right: 8px;
}

.custom-next-button:hover,
.custom-prev-button:hover {
  background-color: #f0f0f0;
}

.custom-next-button:focus,
.custom-prev-button:focus {
  color: #000;
}

.product-image-swiper {
  width: 100%;
  margin-top: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.product-imageContainer-swiper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.swiper-zoom-container {
  cursor: zoom-in; /* Default cursor for zoom */
}

.swiper-zoom-container.zoomed {
  cursor: zoom-out; /* Cursor for when image is zoomed in */
}


@media (max-width: 500px) {
  .custom-next-button,
  .custom-prev-button {
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
  }
}