



.main-categories-home{
 
width: 100%;
padding: 0px;
 justify-content: center;
}

.main-categories-home-inner{
  display: flex;
  margin-top:20px;
  gap:10px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}



.category_image_container-outer{
  
  height: 400px;
  width: 50%;
  box-sizing: border-box;
}

.category_image_container{
  position: relative;
  
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
}




.main-categories-image-overlay{
  opacity: 0.1;
  background-color: #000000;
  position: absolute;
  transition: opacity 0.2s ease;
  width: 100%;
  height: 100%;
}

.category_image_container:hover{
  
  transition:color 0.2s ease
}

.category_image_container:hover > .main-categories-image-overlay{
 opacity: 0.7;
 
}


.category_image_ms{
 
  object-fit: cover;
width: 100%;
height: 100%;
 
}



.category_image_text_ms{
  position: absolute;
  opacity: 0;
  color:white;
  transform: translate(-50%,-40%);
  left: 50%;
  top:50%;
  transition: all 0.5s ease;
}

.category_image_container:hover > .category_image_text_ms{
  opacity: 100;
  transform: translate(-50%,-50%);
  
}


@media only screen and (max-width: 790px) {
  .main-categories-home-inner{
    margin-top:10px;
    flex-direction: column;
  }

  .category_image_container-outer{
  
    width: 100%;
  }
}





.mainSection-card {
  width: 100%;
  height: auto;
  min-height: 350px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; /* Initial state before animation */
  transform: translateX(-100px); /* Initial state before animation */
}

.mainSection-card.fade-in {
  opacity: 1; /* Final state after animation */
  transform: translateX(0); /* Final state after animation */
}

.mainsection-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: 1;
  filter: brightness(60%);
}

.mainsection-items {
  position: absolute;
  color: #fdfbf8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.mainSection-card:hover .mainsection-items {
  opacity: 1;
}

.maincategory-row {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Subcategory-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fdfbf8;
  width: 200px;
  height: 53px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.Subcategory-btn:hover {
  background-color: #333;
  color: #fff;
}

.mainsection-btn {
  width: 150px;
  height: 53px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  opacity: 0;
  transform: scale(0.8);
  background-color: white;
  color: black;
  transition: opacity 0.3s ease, transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.mainsection-btn:hover {
  background-color: black;
  color: white;
}

.mainSection-card:hover .mainsection-btn {
  opacity: 1;
  transform: scale(1);
}

.Main__category--title {
  text-align: center;
  font-weight: 600;
  margin-top: 36px;
  font-size:32px;
}

.mainsection-title {
  font-size: 36px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

/* Adding keyframes for card appearance */
@keyframes cardFadeIn {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
