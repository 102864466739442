/* Hero.css */
.SwiperHero {
  width: 100%;
  height: calc(100vh - 120px);
}

.SwiperSlideHero {
  text-align: center;
  font-size: 18px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.main-hero-ms .swiper-pagination-bullets {
  display: none;
}

.main-hero-ms .swiper-button-next,
.main-hero-ms .swiper-button-prev {
  width: 50px;
  height: 50px;
  background-color: #0000003b;
  /* Customize as needed */
  border-radius: 50%;
  /* Make the arrows circular */
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-hero-ms .swiper-button-next::after,
.main-hero-ms .swiper-button-prev::after {
  font-size: 18px;
  /* Customize arrow size */
  color: #fff;
  /* Arrow color */
}

.main-hero-ms .swiper-button-next {
  right: 10px;
}

.main-hero-ms .swiper-button-prev {
  left: 10px;
}

.SwiperSlideHero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  filter: brightness(60%);
  object-position: center;
}

.carousel-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: brightness(0.75);
}

.hero-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: #fff;
}

.black-overlay-im-carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000062;
  top: 0;
  left: 0;
  z-index: 1;
}

.hero-caption {
  color: #ffffff;
  z-index: 3;
}

.heading-hero {
  font-weight: 300;
  color: #ffffff;
}

.subText-hero {
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
}

.hero-button {
  font-size: 20px;
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  background: #01a401;
  margin-top: 20px;
  transition: 0.3s;
}

.hero-button:hover {
  color: #01a401;
  background-color: rgb(255, 255, 255);
}

.swiper-container-fade .swiper-slide {
  transition-property: opacity;
}

.main-hero-ms {
  overflow: hidden;
  margin-top: 20px;
  border-radius: 5px;
  height: calc(100vh - 104px);
}

.container-hero-ms {
  padding: 10px;
}

.swiper-container-fade .swiper-slide-active {
  opacity: 1;
}

.swiper-container-fade .swiper-slide-next,
.swiper-container-fade .swiper-slide-prev {
  opacity: 0;
}

@media (max-width: 800px) {
  .heading-hero {
    font-size: 32px;
  }

  .hero-container {
    justify-content: center;
  }

  .hero-caption {}

  .subText-hero {
    font-size: 16px;
  }

  .hero-button {
    font-size: 16px;
    padding: 0.8em 1.0em;
    margin-top: 8px;
  }


}